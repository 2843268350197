import React, { Component } from "react";
import Layout from "../components/layout";
import Intro from "../components/intro";
import MapContact from "../images/map_contact.png";
import Nav from "../components/nav";

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      pageContent: [],
      ispageLoader: false,
    };
  }

  async componentDidMount() {
    this.getPageDetailsById();
  }

  componentWillReceiveProps(nextProps) {
    this.getPageDetailsById();
  }
  getPageDetailsById() {
    fetch("https://tatva.com/backendapi/api/v1/cms/602b58b74a831648cdc13836")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            ispageLoader: true,
            pageContent: result,
          });
        },
        (error) => {
          this.setState({
            ispageLoader: true,
            error,
          });
        }
      );
  }

  render() {
    const { pageContent, ispageLoader } = this.state;
    return (
      <Layout>
        {ispageLoader ? (
          <Nav
            active="home"
            pageTitle={pageContent.data?.title}
            title={pageContent.data?.document_title}
            description={pageContent.data?.meta_description}
            keywords={pageContent.data?.meta_tag}
          ></Nav>
        ) : (
          ""
        )}
        <Intro
          title="Contact Us"
          description="Tatva Legal, Hyderabad has offices in the states of Andhra Pradesh and Telangana."
        >
          {" "}
        </Intro>

        <div className="main-container">
          <div id="contact">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="blog-container">
                    <div className="row">
                      <div className="col-md-5 col-sm-12 text-right">
                        <div className="inner">
                          <h4>Telangana</h4>
                          <p>
                            Tatva Legal <br />
                            Tatva House, Plot No. 107A, <br />
                            Road No. 72, Jubilee Hills, <br />
                            Hyderabad - 500 110
                          </p>
                          <hr />
                          <p>
                            T +91 - 40 2358 1000-04 <br />E
                            hyderabad@tatvalegal.com
                          </p>
                          {/* <p>
                            E internships@tlegal.com <br />
                            E careers@tlegal.com
                          </p> */}
                        </div>
                      </div>

                      <div className="col-md-7 col-sm-12 text-left">
                        <div className="inner">
                          <h4> &nbsp; </h4>
                          <p>  For internships please reach out to us at 
                          internships@tlegal.com </p>
                          <p>
                          For career opportunities please reach out to us at careers@tlegal.com</p>

                        </div>
                        </div>
                    </div>

                    <div className="row desktop">
                      <div className="col-md-12 col-sm-12">
                        <div className="contact_map">
                          <div className="left">
                            <a
                              href="https://www.google.com/maps?q=Tatva+House,+Plot+No.+107+A,+Road+No.+72,+Jubilee+Hills,+Hyderabad+500+110,+India&biw=1920&bih=880&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjJhNPr287vAhXr63MBHU1uCWAQ_AUoAXoECAEQAw"
                              rel="noreferrer"
                              target="_blank"
                            ></a>
                          </div>
                          <div className="right">
                            <a
                              href="https://www.google.com/maps/@16.4980264,80.6466953,17z"
                              rel="noreferrer"
                              target="_blank"
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mobile">
                      <div className="col-md-12 col-sm-12">
                        <div className="contact_map">
                          <img src={MapContact} title="" alt="" />
                          <div className="left">
                            <a
                              href="https://www.google.com/maps?q=Tatva+House,+Plot+No.+107+A,+Road+No.+72,+Jubilee+Hills,+Hyderabad+500+110,+India&biw=1920&bih=880&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjJhNPr287vAhXr63MBHU1uCWAQ_AUoAXoECAEQAw"
                              rel="noreferrer"
                              target="_blank"
                            ></a>
                          </div>
                          <div className="right">
                            <a
                              href="https://www.google.com/maps/@16.4980264,80.6466953,17z"
                              rel="noreferrer"
                              target="_blank"
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-end">
                      <div className="col-md-5 col-sm-12">
                        <div className="inner2">
                          <h4>Andhra Pradesh</h4>
                          <p>
                            Tatva Legal <br />
                            Plot No.57, Second Floor, <br />
                            Ramapuram Colony, Poranki, <br />
                            Vijayawada – 521137
                          </p>
                          <hr />
                          <p>
                            T +91 – 91004 78818 <br />E
                            vijaywada@tatvalegal.com
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ContactPage;
